import { useState } from 'react';
import { Link } from 'react-router-dom';
import CartService from '../services/cart-service';
import MenuService from '../services/menu-service';

export default function Header() {

    const pages = MenuService.getPages();
    const [itemsInCart, setItemsInCart] = useState(CartService.getCart().length);

    CartService.onCartUpdated((cart) => {
        setItemsInCart(cart.length);
    });

    let env = MenuService.getEnvironment();

  return <header id="header">
        <h1><Link to="/">TB {env}</Link> React</h1>
        <nav id="nav">
            <ul>
                {pages.map(item => <li key={item.link}><Link to={item.link} className='link depth-1'>{item.text}</Link></li>)}
                <li><Link to="/checkout" className="button">{itemsInCart} items in cart</Link></li>
            </ul>
        </nav>
    </header>;
}