import React from 'react';
import CartService from '../services/cart-service';
import MenuService from '../services/menu-service';

function Products() {
  	document.title = MenuService.getTitle("Products");


	function addToCart(item) {
		CartService.addToCart(item);
	}

	const products = [
            { title: 'Walk Milano', text: 'Walk London Sean tassel loafers in brown milled leather', price: 115, image: 'images/products/205082351-1-black.webp'},
            { title: 'Walk Sean', text: 'Walk London Milano lace up boots in black leather', price: 88, image: 'images/products/205107270-1-brown.webp'},
            { title: 'Reebok BB 4000 II', text: 'Reebok BB 4000 II sneakers in white with blue detail', price: 90, image: 'images/products/204349751-1-white.webp'},
            { title: 'Jack & Jones', text: 'Jack & Jones faux suede slippers in tan', image: 'images/products/204913684-1-almond.webp', price: 34},
            { title: 'Crocs classic', text: 'Crocs classic all terrain clogs in khaki', image: 'images/products/204295159-1-neutral.webp', price: 59},
            { title: 'Vans Knu Skool', text: 'Vans Knu Skool sneakers in navy and white', image: 'images/products/205116880-1-navy.webp', price: 75},
        ]

  return (
    <div className="Products">
		<section id="main" className="container">
			<header>
				<h2>Products</h2>
				<p>What do you want to buy?</p>
			</header>
			<div className="row">				
                {products.map(item => <div className="col-4 col-12-narrower" key={item.title}>
					<section className="box special">
						<span className="image featured"><img src={item.image} alt="" /></span>
						<h3>{item.title}</h3>
						<p>{item.text}</p>
						<h1>${item.price}</h1>
						<ul className="actions special">
							<li><a className="button alt" onClick={() => addToCart(item)}>Add to cart</a></li>
						</ul>
					</section>
				</div>)}
			</div>
		</section>
    </div>
  )
}

export default Products;
