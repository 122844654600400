import React from 'react';
import CartService from '../services/cart-service';
import MenuService from '../services/menu-service';

function Checkout() {
  document.title = MenuService.getTitle("Checkout");

  const cart = CartService.getCart();
  const total = CartService.getTotal();

  return (
    <div className="Checkout">
      <section id="main" className="container medium">
			<header>
				<h2>Complete your purchase</h2>
				<p>Fill in your details bellow</p>
			</header>
			<div className="box" style={{ display: total > 0 ? 'block' : 'none'}}>
				<b>Your cart:</b>
				{cart.map(item => <li key={item.title}>{item.title} - ${item.price}</li>)}
				<b>Total: ${total}</b>
			</div>
			<div className="box">
				<form method="get" action="/thanks">
					<div className="row gtr-50 gtr-uniform">
						<div className="col-6 col-12-mobilep">
							<input type="text" name="name" id="name" defaultValue="" placeholder="Name" />
						</div>
						<div className="col-6 col-12-mobilep">
							<input type="email" name="email" id="email" defaultValue="" placeholder="Email" />
						</div>
						<div className="col-12">
							<input type="text" name="subject" id="subject" defaultValue="" placeholder="Subject" />
						</div>
						<div className="col-12">
							<textarea name="message" id="message" placeholder="Enter your message" rows="6"></textarea>
						</div>
						<div className="col-12">
							<ul className="actions special">
								<li><input type="submit" value="Complete purchase" /></li>
							</ul>
						</div>
					</div>
				</form>
			</div>
		</section>
    </div>
  )
}

export default Checkout;
