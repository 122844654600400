import React from 'react';
import CartService from '../services/cart-service';
import MenuService from '../services/menu-service';

function Thanks() {
  document.title = MenuService.getTitle("Thanks");


  const cart = CartService.getCart();
  const total = CartService.getTotal();
  CartService.resetCart();

  return (
    <div className="Thanks">
      <section id="main" className="container medium">
			<header>
				<h2>Thanks</h2>
				<p>Your things will be shipped in 1-3 days.</p>
			</header>
			<div className="box" style={{ display: total > 0 ? 'block' : 'none'}}>
				<b>You have bought:</b>
				{cart.map(item => <li key={item.title}>{item.title} - ${item.price}</li>)}
				<b>Total: ${total}</b>
			</div>
		</section>
    </div>
  )
}

export default Thanks;
