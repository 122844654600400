const CartService = {
    addToCart(item) {
        let cart = this.getCart();
        if (item) {
            cart.push(item);
        }
        sessionStorage.setItem("cart", JSON.stringify(cart));

        document.dispatchEvent(new CustomEvent('cart', cart));
    },

    getCart() {
        let cart = sessionStorage.getItem("cart") ?? [];
        if (cart.length) {
            cart = JSON.parse(cart);
        }
        return cart;
    },

    getTotal() {
        return this.getCart().reduce((acc, item) => acc + item.price, 0);
    },

    resetCart() {
        sessionStorage.removeItem("cart");
        document.dispatchEvent(new CustomEvent('cart', []));
    },

    onCartUpdated(callback) {
        document.addEventListener('cart', (e) => callback(this.getCart()));
    }
};

export default CartService;