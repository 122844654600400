import { useState } from 'react';
import { Link } from 'react-router-dom';
import CartService from '../services/cart-service';
import MenuService from '../services/menu-service';

export default function MobileMenu() {

  const pages = MenuService.getPages();
    const [itemsInCart, setItemsInCart] = useState(CartService.getCart().length);

    CartService.onCartUpdated((cart) => {
        setItemsInCart(cart.length);
    });

  return <div>
        <div id="navButton">
            <a className="toggle" onClick={() => MenuService.changeMobileMenu()}></a>
        </div>
        <div id="navPanel">
          <nav>
            {pages.map(item => <Link key={item.link} to={item.link} onClick={() => MenuService.changeMobileMenu()} className='link depth-1'>{item.text}</Link>)}
            <p/>
					  <div className="align-center">
              <Link to="/checkout" className="button" onClick={() => MenuService.changeMobileMenu()}>{itemsInCart} items in cart</Link>
            </div>
          </nav>
        </div>
    </div>;
}