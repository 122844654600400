import MenuService from '../services/menu-service';

function Services() {
  document.title = MenuService.getTitle("Services");

  return (
    <div className="Services">
		<section id="main" className="container">
			<header>
				<h2>Services</h2>
				<p>Just an assorted selection of elements.</p>
			</header>
			<div className="row">
				<div className="col-12">
					<section className="box">
						<h3>Lists</h3>
						<div className="row">
							<div className="col-6 col-12-mobilep">

								<h4>Unordered</h4>
								<ul>
									<li>Dolor pulvinar etiam magna etiam.</li>
									<li>Sagittis adipiscing lorem eleifend.</li>
									<li>Felis enim feugiat dolore viverra.</li>
								</ul>

								<h4>Alternate</h4>
								<ul className="alt">
									<li>Dolor pulvinar etiam magna etiam.</li>
									<li>Sagittis adipiscing lorem eleifend.</li>
									<li>Felis enim feugiat dolore viverra.</li>
								</ul>

							</div>
							<div className="col-6 col-12-mobilep">

								<h4>Ordered</h4>
								<ol>
									<li>Dolor pulvinar etiam magna etiam.</li>
									<li>Etiam vel felis at lorem sed viverra.</li>
									<li>Felis enim feugiat dolore viverra.</li>
									<li>Dolor pulvinar etiam magna etiam.</li>
									<li>Etiam vel felis at lorem sed viverra.</li>
									<li>Felis enim feugiat dolore viverra.</li>
								</ol>

								<h4>Icons</h4>
								<ul className="icons">
									<li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
									<li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
									<li><a href="#" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
									<li><a href="#" className="icon brands fa-github"><span className="label">Github</span></a></li>
									<li><a href="#" className="icon brands fa-dribbble"><span className="label">Dribbble</span></a></li>
									<li><a href="#" className="icon brands fa-tumblr"><span className="label">Tumblr</span></a></li>
								</ul>

							</div>
						</div>

						<h4>Actions</h4>
						<ul className="actions">
							<li><a href="#" className="button special">Default</a></li>
							<li><a href="#" className="button">Default</a></li>
							<li><a href="#" className="button alt">Default</a></li>
						</ul>
						<ul className="actions small">
							<li><a href="#" className="button special small">Small</a></li>
							<li><a href="#" className="button small">Small</a></li>
							<li><a href="#" className="button alt small">Small</a></li>
						</ul>
						<div className="row">
							<div className="col-3 col-6-narrower col-12-mobilep">
								<ul className="actions stacked">
									<li><a href="#" className="button special">Default</a></li>
									<li><a href="#" className="button">Default</a></li>
									<li><a href="#" className="button alt">Default</a></li>
								</ul>
							</div>
							<div className="col-3 col-6-narrower col-12-mobilep">
								<ul className="actions stacked">
									<li><a href="#" className="button special small">Small</a></li>
									<li><a href="#" className="button small">Small</a></li>
									<li><a href="#" className="button alt small">Small</a></li>
								</ul>
							</div>
							<div className="col-3 col-6-narrower col-12-mobilep">
								<ul className="actions stacked">
									<li><a href="#" className="button special fit">Default</a></li>
									<li><a href="#" className="button fit">Default</a></li>
									<li><a href="#" className="button alt fit">Default</a></li>
								</ul>
							</div>
							<div className="col-3 col-6-narrower col-12-mobilep">
								<ul className="actions stacked">
									<li><a href="#" className="button special small fit">Small</a></li>
									<li><a href="#" className="button small fit">Small</a></li>
									<li><a href="#" className="button alt small fit">Small</a></li>
								</ul>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<section className="box">
						<h3>Table</h3>

						<h4>Default</h4>
						<div className="table-wrapper">
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Description</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Something</td>
										<td>Ante turpis integer aliquet porttitor.</td>
										<td>29.99</td>
									</tr>
									<tr>
										<td>Nothing</td>
										<td>Vis ac commodo adipiscing arcu aliquet.</td>
										<td>19.99</td>
									</tr>
									<tr>
										<td>Something</td>
										<td> Morbi faucibus arcu accumsan lorem.</td>
										<td>29.99</td>
									</tr>
									<tr>
										<td>Nothing</td>
										<td>Vitae integer tempus condimentum.</td>
										<td>19.99</td>
									</tr>
									<tr>
										<td>Something</td>
										<td>Ante turpis integer aliquet porttitor.</td>
										<td>29.99</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="2"></td>
										<td>100.00</td>
									</tr>
								</tfoot>
							</table>
						</div>

						<h4>Alternate</h4>
						<div className="table-wrapper">
							<table className="alt">
								<thead>
									<tr>
										<th>Name</th>
										<th>Description</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Something</td>
										<td>Ante turpis integer aliquet porttitor.</td>
										<td>29.99</td>
									</tr>
									<tr>
										<td>Nothing</td>
										<td>Vis ac commodo adipiscing arcu aliquet.</td>
										<td>19.99</td>
									</tr>
									<tr>
										<td>Something</td>
										<td> Morbi faucibus arcu accumsan lorem.</td>
										<td>29.99</td>
									</tr>
									<tr>
										<td>Nothing</td>
										<td>Vitae integer tempus condimentum.</td>
										<td>19.99</td>
									</tr>
									<tr>
										<td>Something</td>
										<td>Ante turpis integer aliquet porttitor.</td>
										<td>29.99</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="2"></td>
										<td>100.00</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</section>
				</div>
			</div>
			</section>
    </div>
  )
}

export default Services;
